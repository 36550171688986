import { IconClose } from '@arco-design/web-react/icon';
import { IconAttachment, IconLink } from '@arco-iconbox/react-hkgai-government-icon';
import { FC, ReactNode, useMemo } from 'react';

export type Attachment = {
  type?: 'file' | 'link';
  icon?: ReactNode; // 自定义icon
  title?: string;
  closeable?: boolean;
  onClose?: () => void;
};
const Index: FC<Attachment> = ({ type, icon, title = '', closeable = true, onClose }) => {
  const _icon = useMemo(() => {
    if (icon) {
      return icon;
    }
    if (type === 'file') {
      return <IconAttachment width="20px" height="20px" />;
    }
    if (type === 'link') {
      return <IconLink width="20px" height="20px" />;
    }
    return null;
  }, [type, icon]);
  if (type || title) {
    return (
      <div className="h-8 w-fit max-w-full flex items-center gap-1 relative bg-[#F0F0F0] rounded p-1 pr-3 text-base">
        <div className="w-5 h-5 flex-shrink-0">{_icon}</div>
        <div className="overflow-hidden whitespace-nowrap text-ellipsis">{title}</div>
        {closeable && <IconClose width="20px" height="20px" onClick={onClose} className="cursor-pointer text-[#999999] flex-shrink-0" />}
      </div>
    );
  }
  return null;
};

export default Index;
