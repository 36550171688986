import { isText, omit } from '@hkgai/slate-plugin/dist/lib/utils';
import { PARAGRAPH_KEY } from '@hkgai/slate-plugin/dist/lib/plugins';
import { BaseNode, Location } from '@hkgai/slate-plugin/dist/lib/delta';
import { EditorKit, EditorRaw, getFragmentText, serializeHTML } from '@hkgai/slate-plugin/dist/lib/core';
import { getExtractContent } from '@/api/extract_api';
import { isURL } from '@/utils/is';
import { EditorContent } from '@/store/editorStore';

// 获取一个SlateDom的元素，
export const getNewParagraphNode = (text?: string) => {
  return {
    type: PARAGRAPH_KEY,
    children: [{ text: text ?? ' ' }],
  } as BaseNode;
};

// 获取光标的位置的坐标
export const getCursorCoordinate = () => {
  // 获取当前的Selection对象
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) {
    console.log('没有选择区域或者没有focus位置');
    return null;
  }

  const range = selection?.getRangeAt(0); // 获取光标所在的范围
  const rect = range?.getBoundingClientRect(); // 获取光标所在节点的边界框

  // 计算光标的页面坐标
  const pageX = rect?.left || 0 + window.screenX;
  let pageY = rect?.top || 0 + window.screenY;

  // 如果有选区的话，需要加上高度
  if (!selection?.isCollapsed) {
    pageY += rect?.height;
  }

  return {
    x: pageX,
    y: pageY,
  };
};

export const calculateToolbarTop = (autoScroll = true) => {
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) {
    return null;
  }

  const writringBodyElem = document.getElementById('writing-body');
  const generateWrapper = document.getElementById('generate-wrap');
  const editorContainer = document.getElementById('editor-container');
  const editorWrap = document.getElementById('editor-wrap');

  if (!writringBodyElem || !generateWrapper || !editorContainer || !editorWrap) {
    return 0;
  }

  // 光标选中区域
  const range = selection?.getRangeAt(0);
  const rangeDomRect = range?.getBoundingClientRect();

  // 只有 writringBody 是可滚动的容器，计算选中区域相对位置：
  const writringBodyTop = writringBodyElem?.getBoundingClientRect()?.top || 0;
  const rangeTop = rangeDomRect.top + writringBodyElem.scrollTop;

  // 计算光标选中区域相对writing-body的top位置
  const top = rangeTop + rangeDomRect.height - writringBodyTop + 8;

  const toolbarHight = generateWrapper.getBoundingClientRect().height;

  const maxTop = writringBodyElem.scrollHeight - toolbarHight;

  // 固定底部空间
  if (top > maxTop) {
    // editorWrap.style.paddingBottom = `${toolbarHight}px`;
    editorContainer.scrollTop = editorContainer.scrollHeight;
    generateWrapper.style.top = 'unset';
    generateWrapper.style.bottom = `${toolbarHight}px`;
  } else {
    // editorWrap.style.paddingBottom = `0px`;
    generateWrapper.style.top = `${top}px`;
    generateWrapper.style.bottom = 'unset';
  }
  generateWrapper.style.display = 'block';
  if (autoScroll) {
    generateWrapper.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  }

  return true;
};

export const getReferencesContent = async (references: string[]): Promise<string> => {
  let content = '';

  for (const reference of references) {
    if (isURL(reference)) {
      const res = await getExtractContent(encodeURIComponent(reference));
      content += res;
    }
  }

  return content;
};

export const copyTextUsingClipboardAPI = async (text: string, onSuccess?: () => void) => {
  if (!text) {
    return;
  }
  try {
    await navigator.clipboard.writeText(text);
    onSuccess?.();
  } catch (err) {
    console.error('复制操作失败:', err);
  }
};

// 辅助判断选区是否在目标 盒子里
export const calculateRectIn = (rect: DOMRect, wrapperRect: DOMRect) => {
  return (
    rect.top >= wrapperRect.top &&
    rect.left >= wrapperRect.left &&
    rect.top + rect.height <= wrapperRect.top + wrapperRect.height &&
    rect.left + rect.width <= wrapperRect.left + wrapperRect.width
  );
};

// 统计全文总字数

export const countWords = (text: string) => {
  // 统计中文字符数
  const chineseChars = (text.match(/[\u4e00-\u9fa5]/g) || []).length;

  // 统计英文字符数
  const englishChars = Array.from(text)
    .map(ch => (/[a-zA-Z0-9\s]/.test(ch) ? ch : ' '))
    .join('')
    .split(/\s+/)
    .filter(s => s).length;
  //   (text.match(/[a-zA-Z0-9]/g) || []).length;

  // 统计标点符号数
  const punctuation = (text.match(/[.,;!?()'"—_\u3000-\u303F\uFF00-\uFFEF]/g) || []).length;

  // 计算总字符数
  const totalChars = chineseChars + englishChars + punctuation;

  // console.log('countWords====>', 'chineseChars:', chineseChars, 'englishChars:', englishChars, 'punctuation:', punctuation);

  return totalChars || 0;

  //   // 返回结果对象
  //   return {
  //     chineseChars,
  //     englishChars,
  //     punctuation,
  //     totalChars,
  //   };
};

export const saveFileFromBuffer = (content: Blob, filename: string) => {
  console.log('saveFileFromBuffer', content, filename);

  const URL = window.URL || window.webkitURL;
  const downloadUrl = URL.createObjectURL(new Blob([content], { type: 'text/plain;charset=utf-8' }));
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = `${filename}.docx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(downloadUrl);
};

export const execSelectMarks = (key: string, marks: string[], mutexKeys: string[]): string[] => {
  const isKeyInMarks = marks.indexOf(key) > -1;
  const isKeyInMutexKeys = mutexKeys.indexOf(key) > -1;
  // eslint-disable-next-line no-nested-ternary
  return isKeyInMarks ? omit(marks, [key]) : isKeyInMutexKeys ? [...omit(marks, mutexKeys), key] : [...marks, key];
};

export const getEditorContent = (editor: EditorKit): EditorContent => {
  const rootNode = editor.clipboard.serializeNode({ children: editor.raw.children });
  const plain = getFragmentText(rootNode);
  const html = serializeHTML(rootNode);
  const slateDom = JSON.stringify(editor.raw.children);
  return { html, plain, slateDom };
};

export const getSelectionContent = (editor: EditorKit): EditorContent => {
  const nodes = [];
  let fragment = editor.raw.getFragment();
  while (fragment.length && fragment.length === 1) {
    fragment = fragment[0].children || [];
    // NOTE: 特判单个文本块的情况
    if (fragment.length === 1 && isText(fragment[0])) {
      break;
    }
  }
  nodes.push(...fragment);
  const rootNode = editor.clipboard.serializeNode({ children: nodes });
  const plain = getFragmentText(rootNode);
  const html = serializeHTML(rootNode);
  const slateDom = JSON.stringify(editor.raw.children);
  return { html, plain, slateDom };
};

export const getContentByRange = (editor: EditorKit, range: Location): EditorContent => {
  const nodes = [];
  let fragment = editor.raw.fragment(range);
  while (fragment.length && fragment.length === 1) {
    fragment = fragment[0].children || [];
    if (fragment.length === 1 && isText(fragment[0])) {
      break;
    }
  }
  nodes.push(...fragment);
  const rootNode = editor.clipboard.serializeNode({ children: nodes });
  const plain = getFragmentText(rootNode);
  const html = serializeHTML(rootNode);
  // // const slateDom = JSON.stringify(editor.raw.children);
  return { html, plain };
};
