import './index.scss';
import { FC, useMemo, useRef, useState } from 'react';
import { Button } from '@arco-design/web-react';
import { IconAttachment, IconLink } from '@arco-iconbox/react-hkgai-government-icon';
import GlobalResourcesModal from '@/components/global-upload-modal';
import { ActionType, Resource, ResourceType } from '@/components/upload-modal';
import useLocale from '@/hooks/useLocale';

interface AttachmentToolbarProps {
  onChange?: (files: Resource[], links: Resource[]) => any;
}
const AttachmentToolbar: FC<AttachmentToolbarProps> = ({ onChange }) => {
  const local = useLocale();

  const containerRef = useRef<HTMLDivElement>(null);
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState<ActionType>(ActionType.UPLOAD_ATTACHMENT);

  const tabs = useMemo(() => {
    return [ActionType.UPLOAD_ATTACHMENT, ActionType.ADD_LINK];
  }, []);
  return (
    <div className="attachment-toolbar">
      <Button
        className="ai-button"
        icon={<IconAttachment className="icon" />}
        onClick={() => {
          setDefaultSelectedTab(ActionType.UPLOAD_ATTACHMENT);
          setUploadModalVisible(true);
        }}
      >
        {local['inputbox.ddl.upload.file']}
      </Button>
      <Button
        className="ai-button"
        icon={<IconLink className="icon" />}
        onClick={() => {
          setDefaultSelectedTab(ActionType.ADD_LINK);
          setTimeout(() => {
            setUploadModalVisible(true);
          }, 100);
        }}
      >
        {local['inputbox.ddl.insert.Link']}
      </Button>
      <div ref={containerRef} data-container>
        <GlobalResourcesModal
          tabs={tabs}
          modalProps={{
            visible: isUploadModalVisible,
            onCancel: () => {
              setUploadModalVisible(false);
            },
            unmountOnExit: true,
            getPopupContainer: () => {
              return containerRef.current || document.body;
            },
          }}
          tabsProps={{ activeTab: `${defaultSelectedTab}`, onChange: tab => setDefaultSelectedTab(Number(tab)) }}
          onConfirm={(values: Resource[]) => {
            setUploadModalVisible(() => false);
            const files = values?.filter(item => item.type === ResourceType.FILE) || [];
            const links = values?.filter(item => item.type === ResourceType.ATTACHMENT) || [];
            onChange?.(files, links);
            // console.debug(files, links);
          }}
        />
      </div>
    </div>
  );
};

export default AttachmentToolbar;
