import axios from 'axios';

/**
 */
export const getExtractContent = async (params: string): Promise<string> => {
  const res = await axios.get(`/extract?url=${params}`, { headers: { 'X-App-Id': '2' } });
  if (res.data.data) {
    const div = document.createElement('div');
    div.innerHTML = res.data.data.content;
    return div.textContent || div.innerText;
  }
  return '';
};
