import { Message } from '@arco-design/web-react';
import { BlockerFunction, useBlocker } from '@modern-js/runtime/router';
import { useEffect } from 'react';

export const useRoutingBlocker = (shouldBlock: boolean | BlockerFunction, notification?: string) => {
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      notification && Message.warning({ content: notification });
      blocker.reset();
    }
  }, [blocker]);

  return blocker;
};
